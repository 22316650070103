.splash-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../images/bg.png'); /* Ruta de la imagen de fondo */
    background-size: cover; /* Asegura que la imagen cubra todo el fondo */
    color: rgb(85, 21, 21);
    flex-direction: column;
  }
  
  .splash-screen h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .splash-screen p {
    font-size: 1.5rem;
  }
  